"use client";

import { useParams, usePathname } from "next/navigation";
import { ReactNode, useMemo } from "react";
import { Hyperlink } from "./icons/hyperlink";
import { SidebarNav, SidebarNavAreas } from "./sidebar-nav";
import { useRouterManager } from "@/ui/hooks";
import { useLanguage } from "@/lib/languages/language-provider";

export const getNavAreas = (getTranslation: any): SidebarNavAreas<{ queryString: string }> => {
  return {
    // Top-level
    default: ({ queryString }) => ({
      showSwitcher: true,
      showNews: true,
      direction: "left",
      content: [
        {
          items: [
            {
              name: getTranslation("word-home"),
              icon: Hyperlink,
              href: `/`,
              exact: true,
            },
          ],
        },
      ],
    }),
  };
};

export function AppSidebarNav({
  toolContent,
  newsContent,
}: {
  toolContent?: ReactNode;
  newsContent?: ReactNode;
}) {
  const { slug } = useParams() as { slug?: string };
  const pathname = usePathname();
  const { getQueryString } = useRouterManager();
  const { getTranslation } = useLanguage();
  const nav_areas = getNavAreas(getTranslation);

  const currentArea = useMemo(() => {
    return "default";
  }, [slug, pathname]);

  return (
    <SidebarNav
      areas={nav_areas}
      currentArea={currentArea}
      data={{
        queryString: getQueryString(),
      }}
      toolContent={toolContent}
      newsContent={newsContent}
    />
  );
}
